import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { storageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class apiservice {

  public userdetails: any;
  public token: any;
  public userRoleidget: any;
  public showHead: boolean = false;

  //Live Api Domain.
  // public domain = 'https://api.artbyrs.com/website/';
  // public fileUrl = 'https://api.artbyrs.com/';

  //Test Api Domain.
  public domain = 'https://test-api.artbyrs.com/website/';
  public fileUrl = 'https://test-api.artbyrs.com/';

  //Local Api Domain.
  // public domain = 'http://localhost:5059/website/';
  // public fileUrl = 'http://localhost:5059/';

  constructor(
    public http: HttpClient,
    public router: Router,
    public formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    public storage: storageService,
    public toastr: ToastrService) { }

  // get request
  async getRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.domain + api + data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
      // });
    }).catch(err => {
      //  console.log(err);
    });
  }

  // post request
  postRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.domain + api, data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }

}





