<div class="container-fluid py-5" style="background-color: rgb(250, 250, 250);">
    <div class="row">
        <div class="col-12">
            <span class="mdi mdi-chevron-right" style="float: right; font-size: 80px; cursor: pointer;"
                (click)="nextItem()"></span>
            <span class="mdi mdi-chevron-left" style="float: left; font-size: 80px;cursor: pointer;"
                (click)="prevItem()"></span>
            <div class="row mt-3">
                <div class="col-12 col-md-5 mx-auto">
                    <img [lazyLoad]="apiService?.fileUrl + selectedArtwork?.artworkPhoto" class="img-fluid">
                </div>
                <div class="col-12 col-md-6 ms-md-5 py-3 mx-auto">
                    <div class="row">
                        <div class="col-12 screen">
                            <h4>{{selectedArtwork?.artworkTitle}}</h4><br>
                            <h4>{{selectedArtwork?.artworkSize}}</h4><br>
                            <h4 class="ab">{{selectedArtwork?.artworkMedium}}</h4><br>
                            <h1 class="aa" routerLink="/artists/{{selectedArtwork?.artist?.id}}">
                                {{selectedArtwork?.artist?.displayName}}
                            </h1>
                            <br>
                            <h4>{{selectedArtwork?.artworkPrice}}</h4>
                            <div class="mt-4 mb-2">
                                <button type="button" class="btn ms-0 me-3" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal" style="border-radius: 0px;background: black;
                        color: white;">Buy Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal-->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content" style="height: 25%; width: 80%;">
            <div class="modal-header" style="background-color: #fff;">
                <h5>Contact the Artist</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="background-color: #fff;">
                <div class="icons-menu" style="font-size: 16px;">
                    <a class="iconfont-wrapper" href="tel:{{selectedArtwork?.artist?.mobileNumber}}" target="_blank">
                        <span class="p-2 mdi mdi-cellphone-android modal-text-color">&nbsp;&nbsp;+91
                            {{selectedArtwork?.artist?.mobileNumber}} </span>
                    </a><br>
                    <a class="iconfont-wrapper" href="mailto:{{selectedArtwork?.artist?.emailAddress}}" target="_blank">
                        <span
                            class="p-2 mdi mdi-email modal-text-color">&nbsp;&nbsp;{{selectedArtwork?.artist?.emailAddress}}</span>
                    </a><br>
                    <a class="iconfont-wrapper"
                        href="https://api.whatsapp.com/send?phone=
                                            +91 9821420999&text=Hi%2C%0AI%20would%20like%20to%20discuss%20about%20%20painting!."
                        target="_blank">
                        <i class="modal-text-color"><span class="p-2 mbr-iconfont socicon-whatsapp socicon"></span>Chat
                            with us</i>
                    </a><br>
                </div>
            </div>
        </div>
    </div>
</div>